// productSpecsCompany.js
import Vue from "vue";

export const assignCompanyToSpec = (specId, companyId) => {
  // Suppose your backend is: POST /product-specs/{specId}/assign-company?companyId=...
  return Vue.axios.post(`/product-specs/${specId}/assign-company`, null, {
    params: { companyId }
  });
};

export const removeCompanyFromSpec = (specId, companyId) => {
  // Suppose your backend is: DELETE /product-specs/{specId}/company?companyId=...
  return Vue.axios.delete(`/product-specs/${specId}/company`, {
    params: { companyId }
  });
};
