import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"600px","persistent":""},model:{value:(_vm.internalDialogVisible),callback:function ($$v) {_vm.internalDialogVisible=$$v},expression:"internalDialogVisible"}},[_c(VCard,[_c(VCardTitle,[_vm._v(" Manage Assigned Companies ")]),_c(VCardText,[(_vm.productSpecs && _vm.productSpecs.companies && _vm.productSpecs.companies.length)?_c('div',[_c(VList,{attrs:{"two-line":""}},_vm._l((_vm.productSpecs.companies),function(company){return _c(VListItem,{key:company.id},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(company.name))])],1),_c(VListItemAction,[_c(VBtn,{attrs:{"text":"","color":"error","small":""},on:{"click":function($event){return _vm.removeCompany(company.id)}}},[_vm._v(" Remove ")])],1)],1)}),1)],1):_c('div',[_c('p',{staticClass:"text-subtitle-2"},[_vm._v("No companies assigned.")])]),_c('div',{staticClass:"mt-3"},[_c(VAutocomplete,{attrs:{"items":_vm.allCompanies,"item-text":"name","item-value":"id","label":"Add a Company","dense":""},model:{value:(_vm.selectedCompanyId),callback:function ($$v) {_vm.selectedCompanyId=$$v},expression:"selectedCompanyId"}}),_c(VBtn,{staticClass:"mt-2",attrs:{"color":"primary","small":"","disabled":!_vm.selectedCompanyId},on:{"click":_vm.assignCompany}},[_vm._v(" Assign ")])],1)]),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"text":""},on:{"click":_vm.closeDialog}},[_vm._v("Close")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }