<!-- File: src/components/ManageProductSpecCompaniesDialog.vue -->
<template>
    <v-dialog
      v-model="internalDialogVisible"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          Manage Assigned Companies
        </v-card-title>
  
        <v-card-text>
          <!-- List of currently assigned companies -->
          <div v-if="productSpecs && productSpecs.companies && productSpecs.companies.length">
            <v-list two-line>
              <v-list-item
                v-for="company in productSpecs.companies"
                :key="company.id"
              >
                <v-list-item-content>
                  <v-list-item-title>{{ company.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn text color="error" small @click="removeCompany(company.id)">
                    Remove
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </div>
          <div v-else>
            <p class="text-subtitle-2">No companies assigned.</p>
          </div>
  
          <!-- Add new company -->
          <div class="mt-3">
            <v-autocomplete
              v-model="selectedCompanyId"
              :items="allCompanies"
              item-text="name"
              item-value="id"
              label="Add a Company"
              dense
            ></v-autocomplete>
  
            <v-btn
              class="mt-2"
              color="primary"
              small
              @click="assignCompany"
              :disabled="!selectedCompanyId"
            >
              Assign
            </v-btn>
          </div>
        </v-card-text>
  
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { getAllCompanies } from "@/services/company";
  import { assignCompanyToSpec, removeCompanyFromSpec } from "@/services/productSpecsCompany";
  
  export default {
    name: "ManageProductSpecCompanies",
    props: {
      // Controls visibility of the dialog from the parent
      dialogVisible: {
        type: Boolean,
        default: false
      },
      // The ProductSpecsDTO object with .companies already populated
      productSpecs: {
        type: Object,
        default: () => ({})
      }
    },
    data() {
      return {
        internalDialogVisible: this.dialogVisible,
        allCompanies: [],
        selectedCompanyId: null
      };
    },
    watch: {
      // Whenever parent changes dialogVisible, update the local state
      dialogVisible(newVal) {
        this.internalDialogVisible = newVal;
        if (newVal) {
          this.loadCompanies();
        }
      },
      // If user closes the dialog, emit to the parent
      internalDialogVisible(newVal) {
        if (!newVal) {
          this.$emit("update:dialogVisible", false);
        }
      }
    },
    methods: {
      // Called each time the dialog opens (or if you want it once, do it in mounted())
      loadCompanies() {
        const params_company = {
            page: 0,
            size: 10000,
        };
        getAllCompanies(params_company).then(response => {
          // response.data.content is the array of companies
          const content = response.data.content || [];
          // Sort the companies (customer/exchange) by name
          this.allCompanies = content
            .filter(c => c.type == 'CUSTOMER' || c.type == 'EXCHANGE') // Filter out only customers
            .sort((a, b) => {
            // Safely handle missing names, if any
            const nameA = a.name || "";
            const nameB = b.name || "";
            return nameA.localeCompare(nameB);
          });
        }).catch(() => {
          this.allCompanies = [];
        });
      },
  
      assignCompany() {
        if (!this.productSpecs || !this.productSpecs.id || !this.selectedCompanyId) {
          return;
        }
        assignCompanyToSpec(this.productSpecs.id, this.selectedCompanyId)
          .then(() => {
            // 1) Find the newly assigned company in the "allCompanies" array
            const newCompany = this.allCompanies.find(
                (c) => c.id === this.selectedCompanyId
            );
            // 2) Push that company into productSpecs.companies (if it doesn't already exist)
            if (newCompany) {
                // Only add if it's not already present. 
                // (Remove this check if duplicates are never possible.)
                const alreadyAssigned = this.productSpecs.companies.some(
                (comp) => comp.id === newCompany.id
                );
                if (!alreadyAssigned) {
                this.productSpecs.companies.push(newCompany);
                }
            }
            // 3) Let parent know something changed (e.g., re-fetch the entire table, etc.)
            this.$emit("reload");

            // Clear selection
            this.selectedCompanyId = null;
          })
          .catch(err => {
            console.error(err);
            this.$emit("error", "Error assigning company");
          });
      },
  
      removeCompany(companyId) {
        if (!this.productSpecs || !this.productSpecs.id || !companyId) {
          return;
        }
        removeCompanyFromSpec(this.productSpecs.id, companyId)
          .then(() => {
            // 1) Remove from the local array so the UI updates immediately
            const idx = this.productSpecs.companies.findIndex(c => c.id === companyId);
            if (idx > -1) {
                this.productSpecs.companies.splice(idx, 1);
            }

            // 2) (Optional) also tell the parent to reload if it’s needed
            this.$emit("reload");
          })
          .catch(err => {
            console.error(err);
            this.$emit("error", "Error removing company");
          });
      },
  
      closeDialog() {
        this.internalDialogVisible = false;
      }
    }
  };
  </script>
  
  <style scoped>
  </style>
  